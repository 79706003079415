import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "Container",
    component: () => import("../views/Container.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "about",
        name: "about",
        component: () => import("../views/About.vue"),
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("../views/Contact.vue"),
      },
      {
        path: "cart",
        name: "keranjang",
        component: () => import("../views/Cart.vue"),
      },
      {
        path: "katalog",
        name: "katalog",
        component: () => import("../views/KatalogWraper.vue"),
        children: [
          {
            path: "",
            name: "katalog_produk",
            component: () => import("../views/Catalog.vue"),
          },
          {
            path: "detail/:id",
            name: "katalog_detail",
            component: () => import("../views/DetailHewan.vue"),
          },
          {
            path: "aqiqah/:id",
            name: "katalog_aqiqah",
            component: () => import("../views/DetailAqiqah.vue"),
          },
        ],
      },
      {
        path: "checkout",
        name: "checkout",
        component: () => import("../views/Checkout.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("../views/AdminLogin.vue"),
      },
      {
        path: "katalog/:filter?",
        name: "Admin-Katalog",
        component: () => import("../views/AdminKatalog.vue"),
        meta: {
          needAccess: true,
        },
      },
      {
        path: "form-hewan/:id?",
        name: "Form-Hewan",
        component: () => import("../views/FormHewan.vue"),
        meta: {
          needAccess: true,
        },
      },
      {
        path: "form-aqiqah/:id?",
        name: "Form-Aqiqah",
        component: () => import("../views/FormAqiqah.vue"),
        meta: {
          needAccess: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token_sapiping");
  if (!to.meta.needAccess) {
    next();
  } else if (to.meta.needAccess && !token) {
    next("/admin");
  } else if (to.meta.needAccess && token) {
    try {
      let resp = await axios.get(`${process.env.VUE_APP_BASE_URL}/user_data`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (resp.data.id) {
        to.meta.user = resp.data;
        next();
      } else {
        next("/admin");
      }
    } catch (error) {
      next("/admin");
    }
  } else {
    next();
  }
});

export default router;
