<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card
      class="q-dialog-plugin column"
      style="width: 90vw !important; height: 90vh !important"
    >
      <q-card-section class="column" style="flex-grow: 99">
        <q-carousel
          animated
          v-model="slide"
          arrows
          infinite
          style="flex-grow: 99"
          control-color="primary"
          control-text-color="white"
          control-type="unelevated"
        >
          <q-carousel-slide :name="1">
            <q-video
              class="absolute-full"
              src="https://www.youtube.com/embed/OV_vL4SDVQU"
            />
          </q-carousel-slide>
          <q-carousel-slide v-for="i in 4" :key="i" :name="i + 1">
            <q-img
              width="100%"
              height="100%"
              fit="contain"
              :src="`./Resource/Galery/${i}.png`"
            ></q-img>
          </q-carousel-slide>
        </q-carousel>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          unelevated
          label="close"
          color="primary"
          class="full-width"
          @click="onOKClick"
        ></q-btn
      ></q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { onMounted, ref } from "vue";
export default {
  emits: [...useDialogPluginComponent.emits],
  props: ["current"],
  setup(props) {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    let onOKClick = () => {
      onDialogOK();
    };

    onMounted(() => {
      slide.value = props.current;
    });

    let slide = ref(1);
    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      slide,
    };
  },
};
</script>
