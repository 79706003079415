<template>
  <q-layout view="lHh Lpr lFf">
    <router-view class="l-container my-font Poppins"></router-view>
  </q-layout>
</template>

<script>
import { ref } from "vue";
export default {
  name: "LayoutDefault",
  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.scss";

.stickyTable {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $primary;
    color: white;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
    font-weight: bold;
    // border: 0.1px solid grey;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.l-container {
  height: 100vh;
}
.l-mobile-container {
  height: calc(100vh - 55px);
  margin: auto;
}
.l-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99;
}

.q-tab__indicator {
  height: 4px !important;
}

@font-face {
  font-family: customfont;
  src: url("./css/fonts/times.ttf");
}
@font-face {
  font-family: Poppins;
  src: url("./css/fonts/Poppins-Bold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: Poppins;
  src: url("./css/fonts/Poppins-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url("./css/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

.my-font {
  font-family: "customfont";
}

.Poppins {
  font-family: "Poppins";
}

.l-text-header {
  font-size: 40px !important;
  line-height: 1.8;
  @media only screen and (max-width: 900px) {
    font-size: 36px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-title {
  font-size: 32px !important;
  line-height: 1.8;
  @media only screen and (max-width: 900px) {
    font-size: 26px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 20px !important;
  }
}

.l-text-subtitle {
  font-size: 20px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 16px !important;
  }
}

.l-text-detail {
  font-size: 16px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}

.my_page_container {
  min-height: calc(100vh - 327px);
  padding: 48px 48px 100px 48px;
  @media only screen and (max-width: 900px) {
    padding: 16px 16px 100px 16px;
  }
  @media only screen and (max-width: 500px) {
    padding: 0px 0px 100px 0px;
  }
}
</style>
