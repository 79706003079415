<template>
  <div>
    <q-scroll-area
      ref="myScroll"
      :style="
        isTablet ? 'height: calc(100vh - 50px)' : 'height: calc(100vh - 55px)'
      "
    >
      <!-- carousel  -->
      <q-carousel
        swipeable
        :autoplay="5000"
        id="jumbotron"
        navigation-icon="radio_button_unchecked"
        navigation
        animated
        v-model="slide"
        :style="jumbotronSize"
        infinite
      >
        <q-carousel-slide
          name="first"
          :img-src="`./Resource/jumbo_1.png`"
          style="
            background-size: contain;
            background-position: right bottom;
            background-repeat: no-repeat;
          "
        >
          <div
            class="absolute-left column justify-center items-start custom-caption"
          >
            <div class="l-text-header text-bold" style="max-width: 800px">
              Dapatkan Hewan Qurban pilihan Anda Hanya di sapiping.com
            </div>
            <div class="l-text-subtitle q-mb-xl q-mt-md text-grey-7">
              Berkualitas Terbaik dan sesuai dengan syariat islam
            </div>
            <q-btn
              unelevated
              no-caps
              color="primary"
              text-color="white"
              label="Hubungi Kami"
              size="md"
              type="a"
              target="_blank"
              :href="`https://api.whatsapp.com/send?phone=+6281226137723&text=Assalamualaikum%20boss%20farm%2C%20saya%20ingin%20bertanya`"
            ></q-btn>
          </div>
        </q-carousel-slide>
        <q-carousel-slide
          name="second"
          :img-src="`./Resource/jumbo_2.png`"
          style="
            background-size: cover;
            background-position: left center;
            background-repeat: no-repeat;
          "
        >
        </q-carousel-slide>
        <q-carousel-slide
          name="third"
          :img-src="`./Resource/jumbo_3.png`"
          style="
            background-size: cover;
            background-position: left center;
            background-repeat: no-repeat;
          "
        >
        </q-carousel-slide>
      </q-carousel>
      <!-- carousel end -->

      <!-- why us -->

      <div id="why_us" class="q-pa-lg" style="width: 100vw">
        <div class="text-center text-bold text-h5 q-my-xl wrap">
          Kenapa Harus Memilih <span class="text-primary">BoSS Farm</span>
        </div>
        <div class="row justify-center" :class="{ 'q-pa-none': isMobile }">
          <q-card
            v-for="i in 4"
            :key="i"
            :style="isMobile ? 'width:100%' : 'width: 300px; height:300px;'"
            :class="
              isMobile
                ? 'q-my-sm q-pa-md  row items-center  no-wrap'
                : 'q-ma-md column justify-start q-pt-xl items-center'
            "
          >
            <q-card-section class="bg-secondary">
              <q-img
                :src="`./Resource/why_${i}.png`"
                fit="contain"
                :width="isTablet ? '50px' : '100px'"
                :height="isTablet ? '50px' : '100px'"
              ></q-img>
            </q-card-section>
            <q-card-section
              :class="
                isMobile
                  ? 'text-bold l-text-subtitle '
                  : 'text-center text-bold l-text-subtitle'
              "
            >
              {{ data.why[i - 1] }}
            </q-card-section>
          </q-card>
        </div>
      </div>
      <!-- why us end -->

      <!-- about  -->
      <div
        id="about"
        class="row reverse"
        :class="isMobile ? 'q-pa-md' : ' q-pa-xl'"
        style="width: 100vw"
      >
        <div
          class="col"
          :class="{ 'q-ml-md': !isMobile }"
          style="min-width: 290px"
        >
          <div class="l-text-title text-bold q-mb-md">Sapiping.com</div>
          <div class="l-text-subtitle text-grey-7">
            Sapiping.com merupakan inovasi terbaru dari BoSS Farm berupa
            platform untuk mempermudah aktivitas jual-beli ternak sapi dan domba
            serta olahan hasil ternak. Pelanggan dapat membeli sapi dimana pun
            berada ataupun berkunjung secara langsung ke kandang. Adanya tag QR
            Code yang disematkan pada tubuh sapi, maka pembeli dapat mengetahui
            dengan mudah spesifikasi sapi, status penjualan sapi, dan melakukan
            transaksi secara mandiri hanya dengan memindai QR Code.
          </div>
        </div>

        <div
          class="row"
          :class="isTablet ? 'q-mx-auto' : ''"
          style="position: relative"
        >
          <q-img
            class="col-7"
            style="min-width: 290px"
            :src="`./Resource/abang_3.png`"
            ratio="1"
            fit="contain"
          >
          </q-img>
        </div>
      </div>
      <!-- about end -->

      <!-- how to -->
      <div
        id="how_to"
        :class="isTablet ? 'q-pa-md' : 'q-pa-xl'"
        style="width: 100vw"
      >
        <div class="text-center text-bold text-h5 q-mb-xl">
          Bagaimana Cara Memesan?
        </div>
        <div
          class="q-mx-auto"
          :class="
            isTablet ? 'row items-center justify-center no-wrap' : 'column'
          "
          :style="isTablet ? 'width:100%' : ''"
        >
          <div
            class="items-center"
            :class="isTablet ? 'column' : 'row q-gutter-x-md q-mx-auto '"
            :style="isTablet ? '' : 'width: 70%'"
          >
            <div
              class="bg-secondary row justify-center items-center"
              style="border-radius: 50%"
              :style="
                isTablet
                  ? 'height: 75px; width: 75px;'
                  : 'height: 125px; width: 125px;'
              "
            >
              <q-img
                :width="isTablet ? '45px' : '75px'"
                :src="`./Resource/how_1.png`"
              ></q-img>
            </div>
            <!-- Line -->
            <div
              v-if="isTablet"
              style="border-left: 4px solid #966713; height: 50px"
            ></div>
            <div
              v-else
              style="border-bottom: 4px solid #966713; flex-grow: 99"
            ></div>
            <!-- End Line -->
            <div
              class="bg-secondary row justify-center items-center"
              style="border-radius: 50%"
              :style="
                isTablet
                  ? 'height: 75px; width: 75px;'
                  : 'height: 125px; width: 125px;'
              "
            >
              <q-img
                :width="isTablet ? '45px' : '75px'"
                :src="`./Resource/how_2.png`"
              ></q-img>
            </div>
            <!-- Line -->
            <div
              v-if="isTablet"
              style="border-left: 4px solid #966713; height: 50px"
            ></div>
            <div
              v-else
              style="border-bottom: 4px solid #966713; flex-grow: 99"
            ></div>
            <!-- End Line -->
            <div
              class="bg-secondary row justify-center items-center"
              style="border-radius: 50%"
              :style="
                isTablet
                  ? 'height: 75px; width: 75px;'
                  : 'height: 125px; width: 125px;'
              "
            >
              <q-img
                :width="isTablet ? '45px' : '75px'"
                :src="`./Resource/how_3.png`"
              ></q-img>
            </div>
          </div>

          <div
            class="justify-between"
            :class="isTablet ? 'column q-py-lg' : 'row q-mx-auto q-mt-md'"
            :style="isTablet ? 'height: 325px' : 'width: 70%'"
          >
            <div
              class="text-bold"
              :class="isTablet ? 'text-left q-ml-md' : 'text-center '"
              :style="isTablet ? '' : 'width: 125px'"
              v-for="(val, i) in data.how"
              :key="i"
            >
              {{ val }}
            </div>
          </div>
        </div>
      </div>
      <!-- how to end -->

      <!-- gallery  -->

      <div
        id="galery"
        class="columns"
        :class="isTablet ? 'q-pa-xl' : 'q-pa-sm'"
        style="width: 100vw"
      >
        <div class="text-center text-bold l-text-title">Galeri</div>
        <div class="row">
          <q-img
            :ratio="526 / 460"
            class="col-6 cursor-pointer"
            :src="`./Resource/Galery/2.png`"
            @click="showDialogGalery(1)"
            style="min-width: 290px; border: 4px solid white"
          >
            <div class="absolute-full text-subtitle2 flex flex-center">
              <q-icon name="play_circle_filled" size="xl"></q-icon>
            </div>
          </q-img>

          <div class="col-6 row">
            <q-img
              v-for="i in 4"
              :key="i"
              :ratio="327 / 222"
              class="cursor-pointer col-6"
              style="min-width: 290px; border: 4px solid white"
              :src="`./Resource/Galery/${i}.png`"
              @click="showDialogGalery(i + 1)"
            ></q-img>
          </div>
        </div>
      </div>
      <!-- gallery end -->
      <!-- testi  -->
      <div id="testi" class="q-py-xl" style="width: 100vw">
        <div class="text-center text-bold l-text-title text-white q-mb-lg">
          Kata Mereka Tentang BoSS Farm
        </div>
        <div v-show="!isTablet" class="row justify-evenly">
          <q-card
            class="blurry"
            v-for="i in 3"
            :key="i"
            flat
            style="
              width: 350px;
              height: 300px;
              border: 2px solid white;
              margin: 100px 0;
            "
          >
            <div
              class="q-mx-auto row items-center justify-center not-blurry"
              style="border-radius: 50%"
            >
              <q-avatar
                color="white"
                style="
                  width: 105px;
                  height: 105px;
                  margin-top: -50px !important;
                "
              >
                <img
                  style="width: 100px; height: 100px"
                  :src="`./Resource/testi_${i}.png`"
                />
              </q-avatar>
            </div>
            <q-card-section
              class="text-white text-center text-body1 text-bold"
              >{{ data.testi[i - 1].name }}</q-card-section
            >
            <q-card-section
              class="text-primary text-center text-bold q-py-none"
              >{{ data.testi[i - 1].role }}</q-card-section
            >
            <q-card-section class="text-center text-white"
              >"{{ data.testi[i - 1].quote }}"</q-card-section
            >
          </q-card>
        </div>
        <q-carousel
          v-show="isTablet"
          id="carousel-testi"
          v-model="slideTesti"
          transition-prev="scale"
          transition-next="scale"
          swipeable
          animated
          control-color="white"
          navigation
          padding
          class="text-white"
          style="background-color: rgba(0, 0, 0, 0); overflow: visible"
        >
          <q-carousel-slide
            v-for="i in 3"
            :key="i"
            :name="'testi_' + i"
            class="column no-wrap flex-center"
            style="overflow: visible"
          >
            <q-card
              class="blurry"
              flat
              style="border: 2px solid white; margin: 100px 0; height: 325px"
              :style="isMobile ? 'width: 290px;' : 'width: 350px;'"
            >
              <div
                class="q-mx-auto row items-center justify-center not-blurry"
                style="border-radius: 50%; z-index: 99 !important"
              >
                <q-avatar
                  color="white"
                  style="
                    width: 105px;
                    height: 105px;
                    margin-top: -50px !important;
                  "
                >
                  <img
                    style="width: 100px; height: 100px"
                    :src="`./Resource/testi_${i}.png`"
                  />
                </q-avatar>
              </div>
              <q-card-section
                class="text-white text-center text-body1 text-bold"
                >{{ data.testi[i - 1].name }}</q-card-section
              >
              <q-card-section
                class="text-primary text-center text-bold q-py-none"
                >{{ data.testi[i - 1].role }}</q-card-section
              >
              <q-card-section class="text-center text-white"
                >"{{ data.testi[i - 1].quote }}"</q-card-section
              >
            </q-card>
          </q-carousel-slide>
        </q-carousel>
      </div>

      <!-- testi end -->

      <theFooter></theFooter>
    </q-scroll-area>
  </div>
</template>

<script>
import useBasic from "@/compostable/useBasic";
import Gallery from "../components/Gallery.vue";
import theFooter from "../components/theFooter.vue";
import { computed } from "@vue/runtime-core";
export default {
  components: { theFooter, Gallery },

  setup() {
    const { ref, onMounted, reactive, $q, isMobile, isTablet } = useBasic();

    let slide = ref("first");
    let slideTesti = ref("testi_1");

    let data = reactive({
      why: [
        "Sapi sehat, bersih, & sesuai syariat Islam",
        "Transaksi lebih mudah dengan QR Code",
        "Harga Terjangkau",
        "Gratis Ongkir Se-Jabodetabek",
      ],
      how: [
        "Pilih Produk",
        "Isi Formulir Pembelian dan Bayar",
        "Transaksi Selesai dan Barang Siap Dikirim",
      ],
      testi: [
        {
          name: "Prof. Dr. Ir. H. Sri Wilarso Budi, M.S.",
          role: "Guru Besar IPB",
          quote:
            "Sapinya memiliki kualitas yang baik dan menghasilkan karkas yang relatif lebih banyak dari total bobot",
        },
        {
          name: "Prof. Dr. Ir. H. Radjab Tampubolon",
          role: "Ketua KPU Kota Bogor",
          quote:
            "Tempat penyedia hewan Qurban terbaik dalam pemberian pelayanan. Adminnya ramah, fast respon, dan penjelasannya mudah dimengerti",
        },
        {
          name: "H. Abdul Aziz Hasyim, S.T.",
          role: "Pimpinan Ponpes Bogor",
          quote:
            "Harga lebih terjangkau, sapinya gemuk, badannya kokoh dan sehat. Sangat cocok buat Qurban.",
        },
      ],
    });

    onMounted(() => {
      setScroll();
    });

    let setScroll = () => {
      let temp = myScroll.value.getScroll();
      // myScroll.value.setScrollPercentage("vertical", 1);
    };

    let jumbotronSize = computed(() => {
      if (isTablet.value) return { width: "100vw" };
      else return { height: "calc(100vh - 55px)" };
    });

    let showDialogGalery = (i) => {
      $q.dialog({
        component: Gallery,
        componentProps: {
          current: i,
        },
      });
    };

    let myScroll = ref(null);

    return {
      slide,
      slideTesti,
      myScroll,
      setScroll,
      data,
      showDialogGalery,
      jumbotronSize,
      isMobile,
      isTablet,
    };
  },
};
</script>
<style lang="scss">
#carousel-testi {
  .q-panel {
    overflow: visible;
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/quasar.scss";
.custom-caption {
  text-align: left;
  padding-left: 48px;
  color: black;
}
#testi {
  background-image: url("../../public/Resource/bg_testi.png");
  background-attachment: fixed;

  background-color: #54472f;
  background-blend-mode: soft-light;
  background-size: cover;
}
.blurry {
  background-color: rgb(255 255 255 / 36%);
}

#how_to {
  background-image: url("../../public/Resource/flower.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
#why_us {
  background-color: $secondary;
  background-image: url("../../public/Resource/flower.png");
  background-position: right;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-size: contain;
}
.bg_about {
  background-image: url("../../public/Resource/flower.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
