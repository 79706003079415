<template>
  <div>
    <div class="bg-transparent">
      <q-card
        class="bg-white q-mx-auto"
        style="width: 90vw; margin-top: -50px; z-index: 99; max-width: 1295px"
      >
        <q-card-section
          class="text-bold row items-center justify-between no-wrap"
          style="height: 100px"
        >
          <div
            class="text-bold"
            :class="isTablet ? 'l-text-detail' : 'text-h4'"
          >
            Dapatkan Hewan Qurban Berkualitas Sekarang!
          </div>

          <q-btn
            unelevated
            no-wrap
            no-caps
            color="primary"
            label="Hubungi Kami"
            icon="whatsapp"
            text-color="white"
            size="md"
            type="a"
            target="_blank"
            :href="`https://api.whatsapp.com/send?phone=+6281226137723&text=Assalamualaikum%20boss%20farm%2C%20saya%20ingin%20bertanya`"
          ></q-btn>
        </q-card-section>
      </q-card>
      <div
        class="bg-primary"
        style="margin-top: -50px; padding-top: 50px"
      ></div>
      <div class="bg-primary q-py-lg">
        <div class="q-mx-auto" style="max-width: 1440px">
          <div class="row justify-evenly items-center col-grow">
            <div
              style="min-width: 320px"
              class="q-pl-md col-6 l-text-header text-bold text-white"
              :class="{ 'order-last text-center': isTablet }"
            >
              Sapiping.com
            </div>

            <div
              style="min-width: 320px"
              class="row justify-start text-white col-6"
            >
              <div class="q-pb-lg col-4 q-pl-md" style="min-width: 160px">
                <div class="text-bold l-text-subtitle">Fitur</div>
                <div
                  @click="router.push('/')"
                  class="cursor-pointer l-text-detail"
                >
                  Beranda
                </div>
                <div
                  @click="router.push('/katalog')"
                  class="cursor-pointer l-text-detail"
                >
                  Katalog
                </div>
                <div
                  @click="router.push('/about')"
                  class="cursor-pointer l-text-detail"
                >
                  Tentang Kami
                </div>
                <div
                  @click="router.push('/contact')"
                  class="cursor-pointer l-text-detail"
                >
                  Kontak Kami
                </div>
              </div>
              <div class="q-pb-lg col-4 q-pl-md" style="min-width: 160px">
                <div class="text-bold l-text-subtitle">Follow Us</div>
                <div class="l-text-detail">
                  <a
                    class="text-white cursor-pointer"
                    @click="
                      routeFollow(
                        `https://api.whatsapp.com/send?phone=+6281226137723&text=Assalamualaikum%20boss%20farm%2C%20saya%20ingin%20bertanya`
                      )
                    "
                    >Whatsapp</a
                  >
                </div>
                <div class="l-text-detail">
                  <a
                    class="text-white cursor-pointer"
                    @click="
                      routeFollow('https://www.instagram.com/bossfarm.id/')
                    "
                    >Instagram</a
                  >
                </div>
                <div class="l-text-detail">
                  <a
                    class="text-white cursor-pointer"
                    @click="
                      routeFollow(
                        'https://youtube.com/channel/UCSNsPNgCKA-NkS2l_Ct4WUw'
                      )
                    "
                    >Youtube</a
                  >
                </div>
              </div>
              <div class="q-pb-lg col-4 q-pl-md" style="min-width: 160px">
                <div class="text-bold l-text-subtitle">Alamat</div>
                <span class="l-text-detail">Cibadak,</span>
                <span class="l-text-detail"> Ciampea,</span>
                <span class="l-text-detail"> Kabupaten Bogor,</span>
                <div class="l-text-detail">Jawa Barat</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useBasic from "@/compostable/useBasic";
export default {
  setup() {
    const { router, reactive, isTablet, isMobile } = useBasic();
    let data = reactive({});

    let routeFollow = (url) => {
      window.open(url, "_blank").focus();
    };

    return { data, router, isTablet, isMobile, routeFollow };
  },
};
</script>

<style lang="scss"></style>
