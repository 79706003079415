import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import AppVisibility from 'quasar/src/plugins/AppVisibility.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";
import money from "v-money3";

let myApp = createApp(App);

// provide
myApp.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

myApp.provide("rupiah", (number) => {
  return Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(number);
});

myApp.provide("thousand", (number) => {
  return Intl.NumberFormat("id").format(number);
});
// end of provide

myApp
  .use(Quasar, {
    plugins: {
      Notify,
      Loading,
      Dialog,
      AppVisibility,
    },
    config: {
      loading: {},
    },
    quasarUserOptions,
  })
  .use(store)
  .use(money)
  .use(router)
  .mount("#app");
